<template>
  <article class="view-login bg-wave-gradient">
    <div class="site-wrapper flex-row flex-center-h flex-center-v">
      <div class="login-register flex-row flex-center-h">
        <section class="section--login flex-row flex-center-h flex-center-v">
          <header>
            <img role="img" aria-hidden="true" src="@/assets/svg/infovac-icon.svg" width="100px" />
            <h1>Connexion</h1>
            <p role="alert" class="alert-info flex-item--100">
              Votre ancien compte Infovac n'est pas valide sur cette nouvelle plateforme, vous devez vous en créer un nouveau.
            </p>
          </header>

          <div class="form-wrapper">
            <form method="post" @submit.prevent="signinUser">
              <div class="input-group">
                <label for="login">Adresse e-mail</label>
                <input type="email" name="login" id="login" v-model="loginData.login" required />
              </div>

              <div class="input-group">
                <label for="password">Mot de passe</label>
                <input type="password" name="password" id="password" v-model="loginData.password" required />
              </div>

              <div class="flex-row flex-center-h flex-gap-s">
                <router-link class="button--outline" to="/home">Inscription</router-link>

                <button type="submit" class="button--primary">
                  <svg class="icon" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
                    <path
                      fill="currentColor"
                      d="M10.8.9a.9.9 0 0 1 .9-.9h3.6A2.7 2.7 0 0 1 18 2.7v12.6a2.7 2.7 0 0 1-2.7 2.7h-3.6a.9.9 0 1 1 0-1.8h3.6a.9.9 0 0 0 .9-.9V2.7a.9.9 0 0 0-.9-.9h-3.6a.9.9 0 0 1-.9-.9z"
                    />
                    <path
                      fill="currentColor"
                      d="M6.564 3.864a.9.9 0 0 1 1.272 0l4.5 4.499.006.007a.897.897 0 0 1 .258.627v.006a.898.898 0 0 1-.258.627l-.006.007-4.5 4.5a.9.9 0 1 1-1.272-1.273L9.527 9.9H.9a.9.9 0 1 1 0-1.8h8.627L6.564 5.136a.9.9 0 0 1 0-1.272z"
                    /></svg
                  ><span class="caption">Connexion</span>
                </button>

                <p class="flex-item--100 flex-col flex-center-h">
                  <router-link class="text-medium" to="/password-reset">Mot de passe oublié&nbsp;?</router-link>
                </p>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/login.scss";
</style>

<script>
import { HTTP } from "../http-common";
import { EventBus } from "../event-bus.js";
import { log } from "logrocket";

export default {
  name: "login",

  data: function() {
    return {
      loginData: {
        password: "",
        login: "",
      },
    };
  },

  computed: {
    redirectURL() {
      return this.$route.query.redirect || false;
    },
  },

  methods: {
    // Connexion de l'utilisateur
    loginUser: async function() {
      // clear dashboard filters for the session
      await this.$store.dispatch("STORE_DASHBOARD_FILTERS", "");

      this.$store
        .dispatch("USER_LOGIN")
        .then((response) => {
          // EventBus.$emit("trackEvent", "Connexion", `Utilisateur ${response.data.payload.id} connecté`);

          // User logged in, redirect to dashboard or to previous URL?
          if (this.redirectURL) {
            this.$router.push(this.redirectURL);
          } else {
            this.$router.push("/dashboard");
          }
        })
        .catch((err) => {
          if (!err) {
            // EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : erreur inconnue");
            return console.warn("err unedfined?");
          }
          // Le compte n'est pas vérifié (email opt-in)
          if (err.status === 403) {
            // EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : compte non vérifié");
            return this.$toasted.global.appError({
              message: "Votre compte n’est pas vérifié, <strong>consultez votre boite mail.</strong>",
            });
          }
          // Erreur d'authentification
          else {
            // EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : identifiants incorrects");
            return this.$toasted.global.appError({
              message: "Impossible de vous connecter,<br><strong>vérifiez votre identifiant et votre mot de passe !</strong>",
            });
          }

          // Session expirée
          // this.$toasted.global.appError({message: "Votre session a expirée, veuillez vous reconnecter."});
        });
    },

    // Authentification et Connexion de l'utilisateur
    signinUser: function() {
      this.$store
        .dispatch("AUTH_REQUEST", this.loginData)
        .then(() => {
          // EventBus.$emit("trackEvent", "Authentification", "Authentification réussie");

          this.$toasted.clear();
          // Authorization Header (JWT)
          // (Si un token est déjà enregistré en localStorage, il est passé à chaque requête.)
          // -> L'authentification (AUTH_REQUEST) s'est déroulée avec succès : on ajoute le token à toutes les requêtes :
          HTTP.defaults.headers.common.Authorization = "Bearer " + this.$store.state.userToken;
          // Maintenant on connecte l'utilisateur
          this.loginUser();
        })
        .catch((error) => {
          // EventBus.$emit("trackEvent", "Authentification", "Erreur d’authentification");
          // Le compte n'est pas activé (email opt-in)
          this.$toasted.global.appError({
            message: "Impossible de vous authentifier, <br>vérifiez votre identifiant et votre mot de passe !",
          });
        });
    },
  },
};
</script>
